















































































































































import { Ref, Vue, Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { IEventCalendar, IUpdateCalendar } from '@/types/common';
import { ITeacher } from '@/types/teacher';
import { store } from '@/store';
import VideoCard from '@/components/cards/VideoCard.vue';
import SortButton from '@/components/SortButton.vue';
import { ILessonLMS } from '@/types/lms/lesson-lms';
import { API } from '@/services/api';
import { IUser } from '@/types/user';
import { AUTH } from '@/services/auth';

@Component({
  components: {
    VideoCard,
    SortButton,
  },
})
export default class OrderLessonsPage extends Vue {
  @Ref('calendar') calendar!: any
  filterTeacher: ITeacher | null | undefined = null
  firstAndLastDays: { from: Date, to: Date } = this.$getFirstAndLastDaysOfMonth(new Date())
  lessons: Array<ILessonLMS> | null = null
  events: Array<IEventCalendar> = []
  componentKey = 0;

  focus = ''
  type = 'month'
  selectedEvent = {}
  selectedElement = null
  selectedOpen = false
  weekdays = [1, 2, 3, 4, 5, 6, 0]

  setToday() {
    this.focus = '';
  }
  prev() {
    this.calendar.prev();
  }
  next() {
    this.calendar.next();
  }

  async mounted() {
    await this.init();
  }

  async init() {
    if (this.user) await store.main.actions.fetchMyTeachers();
    await this.fetchLessons();
  }

  @Watch('user')
  async watchUser(val: IUser) {
    if (val) await this.init();
  }

  get user(): IUser {
    return AUTH.getUser() as IUser;
  }

  get myTeachers(): ITeacher[] {
    return store.main.state.myTeachers;
  }

  async updateRange({ start, end }: { start: IUpdateCalendar, end: IUpdateCalendar }) {
    const from = new Date(`${start.date}T00:00:00`);
    const to = new Date(`${end.date}T23:59:59`);
    this.firstAndLastDays = { from, to };
    await this.fetchLessons();
  }

  setEvents() {
    this.events = [];
    if (!this.lessons) return;
    this.events = this.lessons.map((lesson) => {
      const { title, startAt, curriculumSubjects, duration, educationGroupLms, lessonOrdered } = lesson;
      const start = new Date(startAt);
      const end = new Date(start.getTime() + duration * 1000);
      const theme = curriculumSubjects.map(({ title: subtitle }) => subtitle).join(' / ');
      const event: IEventCalendar = {
        name: `${title} / ${theme}`,
        start,
        end,
        color: this.getInfoEvent(lesson),
        timed: false,
        title,
        lesson,
        updatedAt: lessonOrdered?.updatedAt,
        user: this.getUserEvent(lesson),
        readyToGo: this.getLinkEvent(lesson),
        ordered: this.getOrderedStatusEvent(lesson),
        processed: this.getProcessedStatusEvent(lesson),
        details: `
          <p class="text-subtitle-1 white--text" >${this.getSubtitleEvent(lesson)}</p>
          <p>${start.toLocaleTimeString()} — ${end.toLocaleTimeString()}</p>
          <p>${this.$t.title.theme}: ${theme}
          <p>${this.$t.title.group}: ${educationGroupLms.title}</p>
        `,
      };
      return event;
    });
    this.calendar.checkChange();
  }

  async fetchLessons() {
    if (this.filterTeacher?.lmsId) {
      const lessons: Array<ILessonLMS> = (await API.lms.fetchLessonByUserId({
        userId: this.filterTeacher?.lmsId,
        ...this.firstAndLastDays,
      })).data;
      this.lessons = lessons;
    } else { this.lessons = []; }
    this.setEvents();
  }

  async sendOrder(lessonLms: ILessonLMS) {
    if (this.user.role.value !== 'admin' && this.user.role.value !== 'teamleader') {
      this.$notify({ type: 'info', content: this.$t.notify.order.onlyTeamleader });
      this.selectedOpen = false;
      return;
    }
    try {
      const lessonOrdered = (await API.lesson.orderLesson(lessonLms.id)).data;
      if (this.lessons) {
        this.lessons.forEach((lesson) => {
          if (lesson.id !== lessonLms.id) return;
          lesson.lessonOrdered = lessonOrdered;
          lesson.lessonOrdered.user = AUTH.getUser();
        });
      }

      this.setEvents();
      if (lessonOrdered.canceled) this.$notify({ type: 'success', content: this.$t.notify.order.cancel });
      else this.$notify({ type: 'success', content: this.$t.notify.order.create });
    } catch (e: any) {
      this.$notify({ type: 'warning', content: e.response.data.error });
    }
    this.selectedOpen = false;
    this.selectedElement = null;
    this.componentKey = Math.random();
  }

  viewDay({ date }: { date: any }) {
    console.log(`click day ${date}`);
  }

  /**
   * Клик по событию
   */
  showEvent({ nativeEvent, event }: { nativeEvent: any, event: any }) {
    const open = () => {
      this.selectedEvent = event;
      this.selectedElement = nativeEvent.target;
      requestAnimationFrame(() => requestAnimationFrame(() => { this.selectedOpen = true; }));
    };

    if (this.selectedOpen) {
      this.selectedOpen = false;
      requestAnimationFrame(() => requestAnimationFrame(() => open()));
    } else {
      open();
    }
    nativeEvent.stopPropagation();
  }

  getInfoEvent(lessonLms: ILessonLMS): string {
    const { lessonEds, lessonOrdered } = lessonLms;
    if (lessonOrdered && lessonOrdered.canceled) return 'red'; // отменённый урок
    if (lessonOrdered && !lessonOrdered.processed) return 'orange'; // заказанный урок
    if (lessonOrdered && lessonOrdered.processed) return 'blue'; // заказанный проверенный урок
    if (lessonEds && lessonEds.readyToGo) return 'green'; // проверенный урок
    if (new Date(lessonLms.startAt) < new Date()) return 'grey darken-1'; // прошедший урок
    if (new Date(lessonLms.startAt) > new Date()) return 'grey darken-3'; // будущий урок
    return 'grey';
  }

  getSubtitleEvent(lessonLms: ILessonLMS): string {
    const { lessonEds, lessonOrdered } = lessonLms;
    if (lessonOrdered && lessonOrdered.canceled) return 'Заказ этого урока был отменён. Он будет обработан в общей очереди';
    if (lessonOrdered && !lessonOrdered.processed) return `Урок заказан для обработки ${this.$formattingDate(lessonOrdered.updatedAt)}, идёт процесс обработки...`;
    if (lessonOrdered && lessonOrdered.processed) return 'Обработка заказанного урока завершена';
    if (lessonEds && lessonEds.readyToGo) return 'Обработка урока завершена в общей очереди';
    if (new Date(lessonLms.startAt) < new Date()) return 'Прошедший необработанный урок';
    if (new Date(lessonLms.startAt) > new Date()) return 'Запланированный урок';
    return 'grey';
  }

  getLinkEvent(lessonLms: ILessonLMS): string | null {
    const { lessonEds } = lessonLms;
    if (lessonEds && lessonEds.readyToGo) return `/lesson/${lessonEds.id}`;
    return null;
  }

  getOrderedStatusEvent(lessonLms: ILessonLMS): boolean {
    const { lessonOrdered } = lessonLms;
    if (lessonOrdered && !lessonOrdered.canceled && !lessonOrdered.processed) return true;
    return false;
  }

  getProcessedStatusEvent(lessonLms: ILessonLMS): boolean {
    const { lessonOrdered } = lessonLms;
    if (lessonOrdered && lessonOrdered.processed) return true;
    return false;
  }

  getUserEvent(lessonLms: ILessonLMS): string | null {
    const { lessonOrdered } = lessonLms;
    if (lessonOrdered && lessonOrdered.user) return this.$getUserFullName(lessonOrdered.user);
    return null;
  }
}
